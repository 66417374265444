import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddImageForm from "../../hospital/AddImageForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialogBox from "../../../component/cards/DeleteDialogBox";
import { useParams } from "react-router";

const GalleryAdmin = ({ gelleryImg }) => {
  const { id: OmlarId } = useParams();

  const { UrlLink } = useSelector((state) => state.user);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [imageFormShow, setImageFormShow] = useState(false);

  const maxImages = 9; // Limit to 9 images
  const galleryImages = gelleryImg?.slice(0, maxImages); // Show only the first 9 images

  // Function to set the current index
  const serialNumber = (indexNum) => {
    if (indexNum >= 0 && indexNum < galleryImages.length) {
      setCurrentIndex(indexNum);
    }
  };

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  // Display the current image based on the limited array
  const currentImage =
    galleryImages?.length > 0 ? galleryImages[currentIndex] : null;

  //------------------------------------------------------------------------------------------------
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const popupRef = useRef(null);

  const togglePopup = (index) => {
    setOpenPopupIndex(openPopupIndex === index ? null : index);
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpenPopupIndex(null);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the popup
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //---------------------------------------------------------------------------------------
  const [imageId, setImageId] = useState();
  const ImageForm = (Id) => {
    setImageFormShow(!imageFormShow);
    setImageId(Id);
  };

  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    // console.log("deleteActive", key);
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/admin-update-gallery/${OmlarId}/${key}/`,
        headerText: `Confirm Deletion`,
        paraText: `image`,
      });
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {gelleryImg?.slice(0, 9).map((image, index) => (
          <div key={index} className="relative">
            {/* More Options Button */}
            <div className="absolute bottom-0 right-0 z-20">
              <div
                onClick={() => togglePopup(index)}
                className="text-gray-300 hover:bg-gray-500 py-1 rounded-full cursor-pointer"
              >
                <MoreVertIcon />
              </div>
            </div>

            {/* Image */}
            <img
              className="w-full sm:h-48 h-20 object-cover border-2 cursor-pointer"
              src={`${UrlLink}${image?.Image}`}
              alt={`Gallery ${index + 1}`}
              loading="lazy"
              onClick={() => serialNumber(index)}
            />

            {/* Popup Menu */}
            {openPopupIndex === index && (
              <div
                ref={popupRef}
                className="absolute right-0 bottom-0 text-xs z-40 "
              >
                <div className="py-1 flex-row text-gray-700">
                  <div
                    onClick={() => ImageForm(image?.Id)}
                    className="dropdownOption"
                  >
                    <EditOutlinedIcon />
                    Update
                  </div>
                  <div
                    onClick={() => handleDeleteModel(image?.Id)}
                    className="dropdownOption"
                  >
                    <DeleteIcon />
                    Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        {imageFormShow && (
          <AddImageForm handleForm={ImageForm} imageId={imageId} />
        )}
        {/* -----------------Popup List------------------------------------------------------ */}

        {/* image zoom preview------------------------------------------------------------------ */}
        <div>
          {gelleryImg && currentIndex !== null && (
            <div
              className={`fixed top-0 inset-0 flex items-center justify-center z-50 ${
                currentIndex !== null ? "bg-black bg-opacity-85" : "hidden"
              }`}
            >
              <div className="absolute flex gap-4 top-4 right-4">
                <div
                  onClick={() => setCurrentIndex(null)}
                  className="text-white hover:bg-white/10 p-2 rounded-full cursor-pointer"
                >
                  <CloseIcon />
                </div>
              </div>
              {currentImage && currentImage.Image ? (
                <img
                  src={`${UrlLink}${currentImage.Image}`}
                  // src={`https://api.venturoxtech.com/${currentImage.file}`}
                  alt={currentImage.description || "Image"}
                  className=" shadow-lg transition-transform transform duration-300 ease-in-out scale-100 w-full sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
                />
              ) : (
                "Loading..."
              )}
              <div className="absolute w-full bottom-2 flex justify-between  p-4">
                <button
                  type="button"
                  onClick={prevImage}
                  className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full rotate-180"
                >
                  <KeyboardDoubleArrowRightIcon />
                </button>
                <button
                  type="button"
                  onClick={nextImage}
                  className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full "
                >
                  <KeyboardDoubleArrowRightIcon />
                </button>
              </div>
            </div>
          )}
        </div>
        <DeleteDialogBox
          open={open}
          setOpen={setOpen}
          data={deleteModelData}
          // redirect="/tenant/person"
        />
      </div>
    </>
  );
};

export default GalleryAdmin;
