import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/props/InputError";
import { useDispatch, useSelector } from "react-redux";
import { setactiveDeactive } from "../../../store/feature/userSlice";

const initialValues = {
  whatsapp_api_token: "",
};

const WhatsappToken = ({ handleForm,token,businessId }) => {
  const dispatch = useDispatch();
  const { activeDeactive } = useSelector((state) => state.user);

  const supplierCreateSchema = Yup.object({
    whatsapp_api_token: Yup.string().required("Whatsapp token is required."),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: supplierCreateSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      data.append("whatsapp_api_token", values.whatsapp_api_token);

      try {
        const response = await getAxiosWithToken({
          method: "patch", // Corrected from "petch" to "patch"
          url: `Omlar_login_register/admin-update-business/${businessId}/`,
          data: data,
          reqType: "formData",
        });
        if (response.data) {
          alertResponseMsgSuccess(response.data.msg);
          handleForm(); // Close form on successful submission
          action.resetForm();
          dispatch(setactiveDeactive(!activeDeactive))
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.msg) {
          alertResponseMsgError(error.response.data.msg);
        } else {
          alertResponseMsgError("An error occurred");
        }
      }
    },
  });
  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">Whatsapp Token</h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="whatsapp_api_token" className="form-label">
                  Whatsapp token *
                </label>
                <input
                  type="text"
                  id="whatsapp_api_token"
                  name="whatsapp_api_token"
                  placeholder={`${token || 'Enter token'} `}
                  className="popform-input"
                  value={values.whatsapp_api_token}
                  onChange={handleChange}
                />
                <InputError
                  error={errors.whatsapp_api_token}
                  touched={touched.whatsapp_api_token}
                  warning={false}
                />
              </div>

              <div className="flex gap-4 justify-end mt-6">
                <AddButton type="submit" name="Update" />
              </div>
            </form>

            <div className="flex justify-end mt-4">
              {/* Delete button outside the form */}
              {/* <AddButton name="Delete" onClick={() => handleDeleteModel(UpdateId)} /> */}
            </div>
          </div>
        </div>
        {/* <DeleteDialogBox open={open} setOpen={setOpen} data={deleteModelData} /> */}
      </div>
    </>
  );
};

export default WhatsappToken;
