import React from "react";
import { Link } from "react-router-dom";

const Sidebartag = ({ logo, isActive, name,path }) => {
  console.log("isActive",isActive)
  return (
    <Link
      to={path}
      className={`flex items-center gap-4 mx-4 mb-2 px-4 py-3 cursor-pointer text-gray-600 hover:text-white hover:shadow-md hover:shadow-appColor  hover:font-semibold hover:bg-gradient-to-tr from-purple-700 to-appBgColor rounded-borderRedius  translate-x-0 duration-100 ease-out ${
        isActive
          ? "font-semibold bg-gradient-to-tr from-purple-700 to-appBgColor text-white shadow-md"
          : ""
      }`}
    >
      {logo}
      <h1 className="">{name}</h1>
    </Link>
  );
};
export default Sidebartag;
