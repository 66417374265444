import React, { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { alertResponseMsgError } from "../../../function/utilies";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";


const AddEvent = ({ uniqueKey }) => {
  const { isLogged } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `crud/new-feedback/${uniqueKey}/`,
      });
      if (response && response.data) {
        setUpdateData(response.data);
      }
    } catch (error) {
      alertResponseMsgError("Failed to fetch event details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogged && uniqueKey && open) {
      getDetail();
    }
  }, [isLogged, uniqueKey, open]);

  const handleButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleCancel = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  //-------------------------------------------------------------------------------
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        className="px-2 rounded py-1 text-textColor"
      >
        <EditCalendarIcon />
      </button>

      {open && (
        <div
          className="fixed inset-0 flex items-center justify-center overflow-auto top-0 bg-black bg-opacity-50 z-50"
          onClick={handleCancel}
        >
          <div
            className="absolute bg-white p-6 rounded-lg overflow-y-auto shadow-lg max-w-3xl w-full"
            onClick={(e) => e.stopPropagation()}
          >
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="bg-white space-y-4">
                <p><strong>Feedback:</strong> {updateData?.Feedback}</p>
                <p><strong>Mobile:</strong> {updateData?.Mobile_No}</p>
                <p><strong>Email:</strong> {updateData?.Email_Id}</p>
                <div className="flex justify-evenly items-center gap-2">
                {updateData?.Image && (
                  <div className="">
                    <strong>Image:</strong>
                    <img
                      src={updateData.Image}
                      alt="Event"
                      className="w-full h-64 rounded mt-2"
                      onClick={handleImageClick}
                    />
                  </div>
                )}
                {updateData?.Video && (
                  <div className="">
                    <strong>Video:</strong>
                    <video
                      src={updateData.Video}
                      controls
                      className="w-full h-64 rounded mt-2"
                    />
                  </div>
                )}
                </div>
                <button
              onClick={handleCancel}
              type="button"
              className="px-4 py-2 my-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
            >
              clase
            </button>
               
              </div>
            )}
          </div>
          {isModalOpen && (
              <div
                className={`fixed top-0 inset-0  flex items-center justify-center z-50 ${
                  isModalOpen !== null ? "bg-black bg-opacity-85" : "hidden"
                }`}
              >
                <div className="absolute flex gap-4 top-4 right-4 z-20">
                  <div
                    onClick={handleImageClick}
                    className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
                  >
                    <CloseIcon />
                  </div>
                </div>
                {isModalOpen ? (
                  <img
                    src={updateData.Image}
                    alt="Imag"
                    className="w-full shadow-lg transition-transform transform duration-300 ease-in-out scale-100  sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
                  />
                ) : (
                  "Loading..."
                )}
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default AddEvent;
