import React, { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import info from "../../../images/info.png"; // Static image for details
// import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import infoCorner from "../../../images/infoCorner.png";

const Information = ({ information }) => {
  const { DynamicColor } = useSelector((state) => state.user);
  const [showInfo, setShowInfo] = useState(false);

  const InfoHandle = () => {
    setShowInfo(!showInfo);
  };

  return (
    <>
      <div>
        <div
          className="text-center rounded-xl mx-5 my-2"
          style={{ color: DynamicColor }}
        >
          <div className="flex items-center justify-center">
            <img src={infoCorner} alt="info" className="w-20" />
          </div>
          {/* <AutoStoriesOutlinedIcon style={{fontSize:'60px'}}/> */}
          <h1
            className="text-2xl font-extrabold text-white"
            style={{ color: DynamicColor }}
          >
            Information Corner
          </h1>
        </div>

        <div className="flex justify-center">
          <div
            onClick={InfoHandle}
            className="text-center text-sm flex gap-2 cursor-pointer"
          >
            <p>Click Here</p>
            <p
              className="rounded-full flex items-center px-1 text-white max-w-min"
              style={{ background: DynamicColor }}
            >
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "15px" }} />
            </p>
          </div>
        </div>
      </div>

      {showInfo && (
        <div className="popup-firstLayer fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative p-4 w-full  mx-auto max-w-2xl">
            <div className="bg-white p-4 shadow-lg">
              <div className="text-right">
                <button onClick={InfoHandle}>
                  <CloseOutlinedIcon />
                </button>
              </div>
              {information?.length > 0 ? (
                information?.map((item, index) => (
                  <div
                    key={index}
                    className="list-decimal space-y-2 text-sm leading-tight mb-2"
                  >
                    <div className="flex items-start space-x-2">
                      <img
                        src={info}
                        alt="infoIcon"
                        className="w-4 h-4 rounded-full"
                      />
                      <h1>{item.Details}</h1>
                    </div>
                  </div>
                ))
              ) : (
                <p>No information available.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Information;
