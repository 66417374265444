import { useSelector } from "react-redux";
import banner from "../../../images/banner.jpg";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const OurFacilities = ({ facility }) => {
  const { UrlLink } = useSelector((state) => state.user);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [currentVideoUrlVideo, setCurrentVideoUrlVideo] = useState("");

  const serialNumber = (indexNum) => {
    if (indexNum) {
      setCurrentIndex(indexNum);
    }
  };

  //------------------video play----------------------------------------------------------------

  const openModalVideo = (url) => {
    // const videoSrc = `https://api.omlarmulti.com/${url.Video}`;
    setCurrentVideoUrlVideo(url); // Set the current video URL
    setIsModalOpenVideo(true);
  };

  const closeModalVideo = () => {
    setIsModalOpenVideo(false);
    setCurrentVideoUrlVideo(""); // Clear the current video URL when closing
  };

  // Function to go to the next image
  // const nextImage = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
  //   );
  // };

  // Function to go to the previous image
  // const prevImage = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
  //   );
  // };
  // const currentImage =
  //   galleryImages?.length > 0 ? galleryImages[currentIndex] : null;
  // console.log("&#9679;")
  // console.log("facilityName",facility[0]?.Facility)
  // console.log("facilityImage",UrlLink +facility[0]?.facility_image2)

  return (
    <div>
      <div className="space-y-4">
        <div className=" md:px-7 ">
            {facility &&
              facility?.map((facility, index) => (
                <div key={index} className="text-xl text-center font-extrabold">
                  <h1 className="my-2">{facility.Facility}</h1>
                  <div className="grid grid-cols-4 items-center gap-1 justify-items-center">
                    <img
                      onClick={() =>
                        serialNumber(UrlLink + facility.facility_image1)
                      }
                      src={UrlLink + facility.facility_image1 || banner}
                      alt="Img"
                      className="h-[75px] w-[75px] rounded-full object-cover border-2 border-white"
                    />
                    <img
                      onClick={() =>
                        serialNumber(UrlLink + facility.facility_image2)
                      }
                      src={UrlLink + facility.facility_image2 || banner}
                      alt="Img"
                      className="h-[75px] w-[75px] rounded-full object-cover border-2 border-white"
                    />
                    <img
                      onClick={() =>
                        serialNumber(UrlLink + facility.facility_image3)
                      }
                      src={UrlLink + facility.facility_image3 || banner}
                      alt="Img"
                      className="h-[75px] w-[75px] rounded-full object-cover border-2 border-white"
                    />
                    <div className=" relative">
                      <video
                        onClick={() =>
                          openModalVideo(UrlLink + facility.facility_video)
                        }
                        src={UrlLink + facility.facility_video || banner}
                        className="h-[75px] w-[75px] rounded-full object-cover border-2 border-white"
                        preload
                      ></video>
                      <div
                        onClick={() =>
                          openModalVideo(UrlLink + facility.facility_video)
                        }
                        className=" absolute top-5 right-5 sm:top-5"
                      >
                        <PlayCircleFilledWhiteOutlinedIcon
                          style={{ fontSize: "35px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      {/* image zoom preview------------------------------------------------------------------ */}
      <div>
        {currentIndex && currentIndex !== null && (
          <div
            className={`fixed top-0 inset-0  flex items-center justify-center z-50 ${
              currentIndex !== null ? "bg-black bg-opacity-85" : "hidden"
            }`}
          >
            <div className="absolute flex gap-4 top-4 right-4 z-20">
              <div
                onClick={() => setCurrentIndex(null)}
                className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
              >
                <CloseIcon />
              </div>
            </div>
            {currentIndex ? (
              <img
                src={currentIndex}
                // src={`https://api.venturoxtech.com/${currentImage.file}`}
                alt="Imag"
                className="w-full shadow-lg transition-transform transform duration-300 ease-in-out scale-100  sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
              />
            ) : (
              "Loading..."
            )}
            {/* <div className="absolute w-full bottom-2 flex justify-between  p-4">
                    <button
                      type="button"
                      // onClick={prevImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full rotate-180"
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                    <button
                      type="button"
                      // onClick={nextImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full "
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                  </div> */}
          </div>
        )}
      </div>

      {/* video play----------------------------------------------------------------------------- */}
      {isModalOpenVideo && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="relative bg-white p-4 rounded-lg max-w-3xl w-full">
            <button
              className="absolute top-12 right-1 z-40 text-white bg-red-500 px-3 py-1 rounded-full"
              onClick={closeModalVideo}
            >
              X
            </button>
            <video
              className="w-full h-screen sm:h-96"
              src={currentVideoUrlVideo} // Play the selected video in modal
              controls
              autoPlay
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OurFacilities;
