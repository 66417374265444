

const AllExpertise = ({expertise}) => {

  return (
    <div>
      <div className="my-3">
        <div className="grid grid-cols-1  gap-1 md:px-7">
            {expertise &&
              expertise.map((exprt, index) => (
                <p key={index} className="pl-5  leading-snug text-lg  font-extrabold">
                   &#9679; {exprt.Expertise_Name}
            </p>
              ))}
        </div>
      </div>
    </div>
  );
};

export default AllExpertise;
