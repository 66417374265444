import React, { useEffect, useState } from "react";
import PageName from "../../component/props/PageName";
// import logo from "../../images/logo.jpg";
import MasksOutlinedIcon from "@mui/icons-material/MasksOutlined";
import { DetailHading } from "../../component/props/DetailHading";
// import { DoctorCard } from "../../component/cards/DoctorCard";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PathRoute } from "../../router/PathRoute";
import smartphone from "../../images/smartphone.png";
import whatsapp from "../../images/whatsapp.png";
import gmail from "../../images/gmail.png";
import globe from "../../images/globe.png";
import AddDoctor from "../hospital/AddDoctor";
import AddEmpaneledPartner from "../hospital/partner/AddEmpaneledPartner";
import AddFacilities from "../hospital/AddFacilities";
import AddExpertise from "../hospital/AddExpertise";
import AddBlog from "../hospital/AddBlog";
import EmergencyDeskForm from "../hospital/EmergencyDeskForm";
// import gallery from "../../images/gallery.jpg";
import HospitalDetailsForm from "../hospital/HospitalDetailsForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
import UpdatePartner from "../hospital/partner/UpdatePartner";
import banner from "../../images/banner.jpg";
import EventTable from "../event/EventTable";
import WhatsappToken from "./whatsappToken/WhatsappToken";
// import VideoGrid from "../hospital/VideoGrid";
import VideoGridAdmin from "./Media/VideoGridAdmin";
import GalleryAdmin from "./Media/GalleryAdmin";
import OurFacilitiesAdmin from "./facilities/OurFacilitiesAdmin";
import BlogSectionAdmin from "./blog/BlogSectionAdmin";
// import HospitalServiceCard from "../../component/cards/HospitalServiceCard";
import { DoctorCardAdmin } from "../../component/cards/DoctorCardAdmin";
import PartnerAdmin from "./partner/PartnerAdmin";
import ExpertiseAdmin from "./experties/ExpertiseAdmin";
import ServicesAdmin from "./service/ServicesAdmin";
import FeedbackTable from "./feedback/FeedbackTable";
import BannerImageAdimn from "./bannerimage/BannerImageAdimn";

const BusinessDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
  // console.log("param OmlarId", OmlarId);
  const { activeDeactive } = useSelector((state) => state.user);
  const [docterFromShow, srtDocterFromShow] = useState(false);
  const [emplaneleFromShow, srtEmplanelFromShow] = useState(false);
  const [emplaneleUpdateShow, srtEmplanelUpdateShow] = useState(false);
  const [UpdateId, setUpdateId] = useState("");

  const [facilitiesFromShow, srtFacilitiesFromShow] = useState(false);
  const [expertiseFromShow, setExpertiseFromShow] = useState(false);
  const [blogFromShow, setBlogFromShow] = useState(false);
  const [EmergencyShow, setEmergencyShow] = useState(false);

  const [hospitalDetailsFormShow, setHospitalDetailsFormShow] = useState(false);
  const [whatsappTokenFormShow, setWhatsappTokenFormShow] = useState(false);
  const [bannerFrom, setbannerFrom] = useState(false);


  const handledoctoerForm = () => {
    srtDocterFromShow(!docterFromShow);
  };
  const handleEmplaneForm = () => {
    srtEmplanelFromShow(!emplaneleFromShow);
  };
  const handleEmplaneUpdateForm = (Id) => {
    srtEmplanelUpdateShow(!emplaneleUpdateShow);
    setUpdateId(Id);
  };
  const handleFaciliitesForm = () => {
    srtFacilitiesFromShow(!facilitiesFromShow);
  };
  const handleExpertiseForm = () => {
    setExpertiseFromShow(!expertiseFromShow);
  };
  const handleBlogForm = () => {
    setBlogFromShow(!blogFromShow);
  };
  const whatsappToken = () => {
    setWhatsappTokenFormShow(!whatsappTokenFormShow);
  };
  const EmergencyForm = () => {
    setEmergencyShow(!EmergencyShow);
  };

  const HospitalDetailsFormHandle = () => {
    setHospitalDetailsFormShow(!hospitalDetailsFormShow);
  };
  const BannerImage = () => {
    setbannerFrom(!bannerFrom);
  };
  //--------------------Bussiness Details API---------------------------------------------
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [gelleryImg, setGellryImg] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [facility, setFacility] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [doctorData, setDoctordata] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [doctoropd, setDoctoropd] = useState([]);
  const [information, setInformations] = useState([]);
  const [blog, setblog] = useState([]);
  const [pharmacies, setpharmacies] = useState([]);
  const [diagnostic, setdiagnostic] = useState([]);
  const [allfeedback, setallfeedback] = useState([]);

  // console.log("gelleryImg", gelleryImg);
  useEffect(() => {
    const fetchHospitalData = async () => {
      if (!OmlarId) {
        navigate("/404");
        return;
      }

      try {
        const response = await getAxiosWithToken({
          method: "get",
          url: `crud/business/${OmlarId}/data/`,
        });

        if (response.data) {
          setAllDataRaw(response.data);
        } else {
          // navigate("/404");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          await dispatch(logoutUser());
          // navigate("/");
        } else {
          // navigate("/404");
        }
      }
    };

    fetchHospitalData();
  }, [OmlarId, dispatch, navigate, activeDeactive]);
  useEffect(() => {
    if (allDataRaw && allDataRaw !== " ") {
      setGellryImg(allDataRaw.galleries);
      setVideoUrl(allDataRaw?.videos);
      setFacility(allDataRaw.facilities);
      setExpertise(allDataRaw.expertise);
      setDoctordata(allDataRaw.doctors);
      setPartnerData(allDataRaw.partners);
      setDoctoropd(allDataRaw.doctor_opd);
      setInformations(allDataRaw.informations);
      setblog(allDataRaw?.our_blogs);
      setpharmacies(allDataRaw?.farmacies);
      setdiagnostic(allDataRaw?.diagnostics);
      setallfeedback(allDataRaw?.feedbacks);
    }
  }, [allDataRaw]);

  //------------------------------------------------------------------------------------
  const openDetail = (id) => {
    const links = PathRoute.HospitalPreviewLink.replace(":id", id);
    // navigate(links);
    window.open(links, "_blank");
  };

  //--------------------------------------------------------------------------------------------
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    allDataRaw.Banner_Image &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image}`,
    allDataRaw.Banner_Image1 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image1}`,
    allDataRaw.Banner_Image2 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image2}`,
    allDataRaw.Banner_Image3 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image3}`,
    allDataRaw.Banner_Image4 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image4}`,
    allDataRaw.Banner_Image5 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image5}`,
    allDataRaw.Banner_Image6 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image6}`,
    allDataRaw.Banner_Image7 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image7}`,
    allDataRaw.Banner_Image8 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image8}`,
    allDataRaw.Banner_Image9 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image9}`,
  ].filter(Boolean); // filter out null or undefined images

  // Automatically change slide every 3 seconds (3000ms)
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 7000);

    // Clean up the interval on component unmount
    return () => clearInterval(slideInterval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevImage = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };
  return (
    <div className="md:p-4">
      <PageName name="Business" />
      <div
        onClick={() => openDetail(OmlarId)}
        className="flex justify-end text-xl font-semibold"
      >
        <Link>Preview</Link>
      </div>
      {/* Hospital Info Section */}
      <div className="flex justify-center mt-5">
        {/* About Hospital------------------------------------------------------------------ */}
        <div className="w-full shadow-md ">
          {/* Card Header */}
          <div className=" relative w-full">
            {/* banner--------------------------------------------------- */}
            <div className=" relative w-full">
              <div className="relative w-full sm:h-96 h-40 overflow-hidden">
                
                <div
                  className={`flex  ${
                    currentSlide === 0
                      ? ""
                      : "transition-transform duration-700"
                  }`}
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  
                  {images.map((src, index) => (
                    <img
                      key={index}
                      src={src}
                      alt={`Slide ${index + 1}`}
                      className="w-full sm:h-96 h-40 object-cover flex-shrink-0"
                    />
                  ))}
                </div>
                <div className="absolute w-full top-1/2 flex justify-between  p-2">
                  <button
                    type="button"
                    onClick={prevImage}
                    className="bg-white/70  text-black flex items-start justify-center rounded-full rotate-180"
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </button>
                  <button
                    type="button"
                    onClick={nextImage}
                    className="bg-white/70  text-black  flex items-start justify-center rounded-full "
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </button>
                </div>
              </div>
            </div>
            
            <div className=" absolute -bottom-14 md:left-32  left-6">
              <img
                src={`${
                  allDataRaw.Logo
                    ? `https://api.omlarmulti.com/media/business/` +
                      allDataRaw.Logo
                    : banner
                }`}
                alt="banner"
                className="sm:w-44 sm:h-44 w-28 h-28 rounded-full ring-2 ring-white bg-white"
              />
            </div>
          </div>
          <div className=" bg-white">
            <div className=" flex items-end justify-end">
              <h2
                onClick={HospitalDetailsFormHandle}
                className="rounded-full m-2 p-1 cursor-pointer bg-gray-200"
              >
                <EditOutlinedIcon />
              </h2>
              <h2
                onClick={BannerImage}
                className="rounded-full m-2 p-1 cursor-pointer bg-gray-200"
              >
                <EditOutlinedIcon />
              </h2>
            </div>

            <h2 className=" flex justify-center font-bold text-2xl p-4">
              {allDataRaw?.Business_Name || "NA"}
            </h2>
          </div>
          <div className=" md:p-10 p-3 bg-white rounded-lg overflow-hidden">
            <div className="md:mb-12 mb-6">
              {/* About Us */}
              <div>
                <h5 className="font-bold text-lg">About Us:</h5>
                <p className="mt-2">{allDataRaw?.About || "NA"}</p>
              </div>

              {hospitalDetailsFormShow && (
                <HospitalDetailsForm
                  handleForm={HospitalDetailsFormHandle}
                  businessId={OmlarId}
                  data={allDataRaw}
                />
              )}
              {bannerFrom && (
                <BannerImageAdimn
                  handleForm={BannerImage}
                  businessId={OmlarId}
                  data={allDataRaw}
                />
              )}
            </div>
            {/*Event Table ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Event"
                logo={<EditCalendarIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleBlogForm}
              />
              <div className="md:p-6  space-y-4">
                <EventTable />
              </div>
              {blogFromShow && <AddBlog handleForm={handleBlogForm} />}
            </div>
            {/*whatsapp_api_token ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="whatsapp Token"
                logo={<ConfirmationNumberIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleBlogForm}
              />
              <div className="p-6  space-y-4">
                <p
                  className="text-lg  font-semibold text-blue-600 cursor-pointer "
                  onClick={whatsappToken}
                >
                  {allDataRaw?.whatsapp_api_token ||
                    "Whatsapp token not available"}
                </p>
              </div>
              {whatsappTokenFormShow && (
                <WhatsappToken
                  handleForm={whatsappToken}
                  token={allDataRaw?.whatsapp_api_token}
                  businessId={OmlarId}
                  // updateValue={updateValue}
                />
              )}
            </div>
            {/*Our Gallery ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Gallery"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={ImageForm}
              />
              {/*gallery--------------------------------------------------- */}
              <div className="my-2">
                <GalleryAdmin gelleryImg={gelleryImg} />
              </div>
            </div>
            {/*Our Video ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Video"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={videoForm}
              />
              <div className="my-5">
                <VideoGridAdmin videoUrl={videoUrl} />
              </div>
            </div>
            {/* Our Facilities---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Facilities"
                logo={<QueueOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleFaciliitesForm}
              />
              <div className="font-semibold mt-4 mb-8">
                <OurFacilitiesAdmin facility={facility} />
              </div>
              {facilitiesFromShow && (
                <AddFacilities handleForm={handleFaciliitesForm} />
              )}
            </div>
            {/* Our Empaneled Partner---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Empaneled Partner"
                logo={
                  <CorporateFareOutlinedIcon style={{ fontSize: "40px" }} />
                }
                // handleForm={handleEmplaneForm}
              />
              <div className="p-4 mt-2 mb-8">
                <PartnerAdmin partnerData={partnerData} />
              </div>
              {emplaneleFromShow && (
                <AddEmpaneledPartner handleForm={handleEmplaneForm} />
              )}
              {emplaneleUpdateShow && (
                <UpdatePartner
                  UpdateId={UpdateId}
                  handleForm={handleEmplaneUpdateForm}
                />
              )}
            </div>
            {/* Doctor Panal---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Doctor Panal"
                logo={<MasksOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handledoctoerForm}
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 md:px-12">
                {doctorData?.map((data, index) => (
                  <DoctorCardAdmin
                    color={"#a64686"}
                    key={index + "____" + data.id}
                    name={data.Doctor_Name}
                    drId={data.Id}
                    qualification={data.Qualification}
                    photo={data.Photo}
                    fee={data.Doctor_Fee}
                    day={data.Day}
                    time={data.Time}
                    doctoropd={doctoropd}
                    allData={data}
                  />
                ))}
              </div>
              {docterFromShow && <AddDoctor handleForm={handledoctoerForm} />}
            </div>
            <div className="my-4">
              <DetailHading
                name="Sevices"
                logo={<MasksOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handledoctoerForm}
              />
              {/* Links Section */}
              <div className=" flex justify-around">
                {pharmacies?.map((pharma) => (
                  <div
                    key={pharma.id || pharma.Shop_Name}
                    className="flex gap-4 items-center"
                  >
                    <ServicesAdmin
                      img={pharma.Image}
                      // handlePharmacyServicesForm={handlePharmacyServicesForm}
                      name={pharma.Shop_Name}
                      EnqNo={pharma.Enquiry_Number}
                      tag="PHARMACY SERVICES"
                      Info={"pharma"}
                      allData={pharma}
                    />
                  </div>
                ))}
                {/* {pharmacyServicesForm && (
              <PharmacyServicesForm handleForm={handlePharmacyServicesForm} />
            )} */}
                {diagnostic?.map((diagno) => (
                  <div
                    key={diagno.id || diagno.Shop_Name}
                    className="flex gap-4 items-center"
                  >
                    <ServicesAdmin
                      img={diagno.Image}
                      Info={"diagno"}
                      // handlePharmacyServicesForm={handleDiagnosticLabTestsForm}
                      name={diagno.Diagnostic_Hospital_Name}
                      EnqNo={diagno.Enquiry_Number}
                      tag="DIAGNOSTIC & LAB TESTS"
                      allData={diagno}
                    />
                  </div>
                ))}
                {/* {diagnosticLabTestsForm && (
              <TestForm handleForm={handleDiagnosticLabTestsForm} />
            )} */}
              </div>
            </div>

            <div className="my-4 ">
              <div className="">
                <DetailHading
                  name="Information"
                  logo={<ImportContactsIcon style={{ fontSize: "40px" }} />}
                  // handleForm={handledoctoerForm}
                />
                <div className="pl-8 my-3 ">
                  {information?.map((info, index) => (
                    <ol
                      key={index}
                      start={index + 1}
                      className="list-decimal space-y-2  leading-tight mb-2"
                    >
                      <li>{info.Details}</li>
                    </ol>
                  ))}
                </div>
              </div>
            </div>

            {/*Our Expertise ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Expertise"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleExpertiseForm}
              />
              <div className="p-6  space-y-4">
                <ExpertiseAdmin expertise={expertise} />
              </div>
              {expertiseFromShow && (
                <AddExpertise handleForm={handleExpertiseForm} />
              )}
            </div>

            {/*Our Blog ---------------------------------------------------- */}
            <div>
              <DetailHading
                name="Our Blog"
                logo={<Groups2OutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleBlogForm}
              />
              <div className="p-6  space-y-4">
                <BlogSectionAdmin blog={blog} />
              </div>
            </div>

            <div>
              <DetailHading
                name="Contact Us"
                logo={<AddIcCallOutlinedIcon style={{ fontSize: "40px" }} />}
                // handleForm={EmergencyForm}
              />
              <div className="md:p-6 bg-gray-100">
                <div className="space-y-8">
                  {/* Address Section */}
                  {/* Address Section */}
                  <div className="">
                    <h1 className="text-xl mb-2 font-semibold">
                      {/* <FiberManualRecordIcon fontSize="small" /> */}
                      Address :
                    </h1>
                    <p className=" w-full pl-5 text-sm">
                      {allDataRaw?.Address || "Address"}
                    </p>
                  </div>
                  <div className=" px-2 ">
                    {/* <h3 className="text-lg font-semibold">Find Us On The Map</h3> */}
                    <div className="relative w-full h-32">
                      <iframe
                        // src={allDataRaw.Google_Map}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.592388042031!2d82.9600448!3d25.4394621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dcd9976979b%3A0xbebb88f2a5399efe!2sAshrisha%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1695657121727!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        title="Ashrisha IT Solutions Location"
                      ></iframe>
                      {/* <div className="mt-2">
                <a
                  href="https://www.google.com/maps/dir/24.9195647,83.7948673/ashrisha+it+solutions/@25.1780436,82.7318979,9z"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Click here for directions
                </a>
              </div> */}
                    </div>
                  </div>

                  {/* Emergency Desk Section */}
                  <div className="bg-white p-4 rounded-lg shadow-md ">
                    <div className="my-4">
                      <h1 className="text-xl mb-2 font-semibold text-red-600 p-2">
                        Emergency Desk :
                      </h1>
                      <div className="md:px-12 pl-5 grid md:grid-cols-2">
                        <div className=" ">
                          <h1 className="text-lg  font-semibold py-2">
                            &#9679; 24x7 Emergency Service
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Emergency_No || "Ambulance Contact"}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold py-2">
                            &#9679; Hospital Ambulance Contact No.
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Ambulance_Contact ||
                              "Ambulance Contact"}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold  p-2">
                            &#9679; Trauma & Accident Care
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Trauma_Accidental_Care ||
                              "Trauma Accidental Care"}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold  p-2">
                            &#9679; Emergency Care Dr. Contact No.
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Emergency_Care_Doctor ||
                              "Emergency No."}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold  p-2">
                            &#9679; Blood Bank Service
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Blood_Bank_No || "Emergency No."}
                          </p>
                        </div>
                        <div className=" ">
                          <h1 className="text-lg  font-semibold  p-2">
                            &#9679; Telemedicine for Emergency Consultations
                          </h1>
                          <p className="text-lg  font-semibold text-blue-600 ">
                            {allDataRaw?.Telemedicine_for_Emergency_Consultations ||
                              "Emergency No."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Social Media Section */}
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">Contact Us:</h3>
                    <div className=" md:px-12 pl-5 my-2">
                      <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                        <div>
                          <img src={smartphone} alt="" className="w-6 h-6" />
                        </div>
                        <p> {allDataRaw?.Contact || " Contact"}</p>
                      </div>
                      <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                        <div>
                          <img src={whatsapp} alt="" className="w-6 h-6" />
                        </div>
                        <p>{allDataRaw?.WhatsApp_number || " Contact"}</p>
                      </div>
                      <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                        <div>
                          <img src={gmail} alt="" className="w-6 h-6" />
                        </div>
                        <p>{allDataRaw?.Email || " Email"}</p>
                      </div>
                      <div className="flex items-center mb-4 gap-2 text-xl text-center  my-7">
                        <div>
                          <img src={globe} alt="" className="w-6 h-6" />
                        </div>
                        <p>{allDataRaw?.Website || "Website"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {EmergencyShow && (
                <EmergencyDeskForm handleForm={EmergencyForm} />
              )}
            </div>
            {/*Feedbacks Table ---------------------------------------------------- */}
            <div className="my-2">
              <DetailHading
                name="Feedbacks"
                logo={<EditCalendarIcon style={{ fontSize: "40px" }} />}
                // handleForm={handleBlogForm}
              />
              <div className="md:p-6  space-y-4">
                <FeedbackTable allfeedback={allfeedback} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetail;
