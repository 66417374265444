import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import InputError from "../../component/props/InputError";


const initialValues = {
  Our_Blog: "",
  blogVideo: null,
  blogImage1: null,
  blogImage2: null,
  blogImage3: null,
};

const AddBlog = ({ handleForm, detailsText }) => {
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
console.log("detailsText",detailsText)
  const validationSchema = Yup.object({
    Our_Blog: Yup.string().required("Blog text is required"),
    // blogImage1: Yup.mixed().required("At least one image is required"),
  });

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      let data = new FormData();
      data.append("Our_Blog", values.Our_Blog);
      if (values.blogImage1) data.append("blogImage1", values.blogImage1);
      if (values.blogImage2) data.append("blogImage2", values.blogImage2);
      if (values.blogImage3) data.append("blogImage3", values.blogImage3);
      if (values.blogVideo) data.append("blogVideo", values.blogVideo);

      try {
        const response = await getAxiosWithToken({
          method: "patch",
          url: `crud/admin-update-blog/${OmlarId}/${detailsText.Id}/`,
          data,
          reqType: "formData",
        });
        if (response.data) {
          alertResponseMsgSuccess("Successful");
          handleForm();
          actions.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        navigate("/");
        alertResponseMsgError(
          error.response?.data?.msg || "An error occurred"
        );
      }
    },
  });

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFieldValue(name, files[0]);
  };
  useEffect(() => {
    if (detailsText) {
      setFieldValue("Our_Blog", detailsText.Our_Blog);
    }
  }, [detailsText, setFieldValue]);
  
  return (
    <div className="popup-firstLayer">
      <div className="relative p-4 w-full my-6 mx-auto max-w-3xl">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center">Blog</h2>

          <form onSubmit={handleSubmit}>
            {/* Blog Title Field */}
            <div className="mb-4">
              <label htmlFor="Our_Blog" className="form-label">
                Blog *
              </label>
              <textarea
                id="Our_Blog"
                name="Our_Blog"
                placeholder="Enter blog text"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={(e) => setFieldValue("Our_Blog", e.target.value)}
                value={values.Our_Blog}
              />
              {errors.Our_Blog && touched.Our_Blog && (
                <InputError errorMessage={errors.Our_Blog} />
              )}
            </div>

            {/* Image Upload Fields */}
            {["blogImage1", "blogImage2", "blogImage3"].map((imageField, index) => (
              <div key={index} className="mb-4">
                <label htmlFor={imageField} className="form-label">
                  Blog Image {index + 1} {index === 0 ? "*" : ""}
                </label>
                <input
                  type="file"
                  id={imageField}
                  name={imageField}
                  accept="image/*"
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  onChange={handleFileChange}
                />
              </div>
            ))}

            {/* Video Upload Field */}
            <div className="mb-4">
              <label htmlFor="blogVideo" className="form-label">
                Blog Video
              </label>
              <input
                type="file"
                id="blogVideo"
                name="blogVideo"
                accept="video/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={handleFileChange}
              />
            </div>

            <div className="flex justify-end mt-6">
              <AddButton type="submit" name="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
