import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/props/InputError";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const initialValues = {
  Adv_Image_video: "",
  Type: "",
};

const AddAds = ({ handleForm }) => {
  const CreateSchema = Yup.object({
    Type: Yup.string()
      .required("Please select either 'Image' or 'Video'")
      .oneOf(["image", "video"], "Invalid type selected"),
      Adv_Image_video: Yup.mixed()
      .required("File is required.")
      .test(
        "fileSize",
        "File size should be less than 2 MB",
        (value) => !value || (value && value.size <= 2000000)
      )
      .test(
        "fileType",
        "File format should be JPG, JPEG, PNG, or MP4 (for videos)",
        (value) =>
          !value ||
          [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "video/mp4",
          ].includes(value.type)
      ),
  });

  const { isLogged } = useSelector((state) => state.user);

  const [docs, setDocs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gelleryImg, setGellryImg] = useState([]);
  const [preview, setPreview] = useState();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CreateSchema,
      onSubmit: async (values, action) => {
        setLoading(true);

        const data = new FormData();
        data.append("Type", values.Type);
        if (docs) data.append("Adv_Image_video", docs);

        try {
          const response = await getAxiosWithToken({
            method: "put",
            url: "crud/adv-banner-video/3/",
            data: data,
            reqType: "formData",
          });

          if (response.data) {
            alertResponseMsgSuccess("Successful...");
            handleForm(); // Close form on success
            action.resetForm();
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(
            error.response?.data?.msg || "An error occurred"
          );
        } finally {
          setLoading(false);
        }
      },
    });
  console.log("docssssssss",docs)
  const handleChangeFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      if (file.size > 2000000) {
        alertResponseMsgError("File size should be less than 2 MB");
        return;
      }

      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "video/mp4",
      ];
      if (!allowedTypes.includes(file.type)) {
        alertResponseMsgError(
          "File format should be JPG, JPEG, PNG, or MP4 (for videos)"
        );
        return;
      }

      setDocs(file);
      setFieldValue("Adv_Image_video", file);

      if (file.type.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(file);
        setPreview(imageUrl);
      } else {
        setPreview(null); // Clear preview for non-image files
      }
    }
  };

  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `crud/adv-banner-video/`,
      });
      if (response.data) {
        setGellryImg(response.data.results);
      }
    } catch (error) {
      alertResponseMsgError("Failed to fetch event details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogged) {
      getDetail();
    }
  }, [isLogged]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={handleForm}
    >
      <div
        className="absolute bg-white p-6 rounded-lg shadow-lg max-w-md w-full"
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>

          {/* File Upload Field */}
          <div className="mb-6">
            <label htmlFor="Adv_Image_video" className="form-label">File</label>
            <input
              type="file"
              id="Adv_Image_video"
              name="Adv_Image_video"
              onChange={handleChangeFile}
              className="form-input"
            />
            <InputError error={errors.Adv_Image_video} touched={touched.Adv_Image_video} warning={false} />
          </div>

          {/* Type Selector */}
          <div className="mb-6">
            <label htmlFor="type" className="form-label">Type</label>
            <select
              name="Type"
              onChange={handleChange}
              value={values.Type}
              id="type"
              className="form-input"
            >
              <option value="">Select Type</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
            </select>
            <InputError error={errors.Type} touched={touched.Type} warning={false} />
          </div>

          {/* Preview */}
          <div className="flex justify-center bg-gray-100 p-2">
            {preview ? (
              <img src={preview} alt="Preview" className="w-full   ring-white bg-white" />
            ) : (
              gelleryImg?.map((item, index) => (
                item.Type === "video" ? (
                  <video
                    key={index}
                    src={item.Adv_Image_video}
                    controls
                    className="w-full  object-cover border-2"
                  />
                ) : (
                  <img
                    key={index}
                    src={item.Adv_Image_video}
                    alt={`Gallery ${index + 1}`}
                    className="w-full  object-cover border-2"
                  />
                )
              ))
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-between mt-3">
            {loading ? (
              <AddButton type="button" name="Adding..." />
            ) : (
              <AddButton type="submit" name="Submit" />
            )}
            <button
              onClick={handleForm}
              type="button"
              className="px-4 py-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAds;
