import React from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import semiborder from "../../images/semiborder.png";

const HospitalServiceCard = ({ name, logo, border, handleForm,color }) => {

  return (
    <div>
      <div className={` relative leading-tight `}>
        {/* <div className={`absolute w-20 h-10   flex items-center justify-center  rounded-full    border-t-4  border-[#c762ba] cursor-pointer`}
        ></div> */}
        <div className="relative text-center">
          {border === "top" ? (
            <div className="absolute flex items-center justify-center  w-full">
              <img src={semiborder} alt="semiborder" className="md:w-44 w-20" />
            </div>
          ) : null}
          <div className="">
          <div  className=" pt-3 md:pt-8 ">
            <h1 className="text-[10px] md:text-sm font-bold px-2 md:px-24">{name}</h1>
            <div  style={{ color: color }}>{logo}</div>
          </div>

          {/* Click here section */}
          <div onClick={handleForm} className="relative z-10 cursor-pointer">
            <p className="md:text-xs text-[10px]">Click here</p>
            <div className="flex justify-center p-[1px]">
              <p className="flex text-center rounded-full text-white  max-w-min z-50" style={{ background: color }}>
                <ArrowForwardIosOutlinedIcon style={{ fontSize: "10" }} />
              </p>
            </div>
          </div>
          </div>
          {border === "bottom" ? (
            <div className="absolute flex items-center justify-center  w-full ">
              <img
                src={semiborder}
                alt="semiborder"
                className="md:w-44 w-20 -mt-9 md:-m-16 rotate-180"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HospitalServiceCard;
