import { useSelector } from "react-redux";
import banner from "../../../images/banner.jpg";
// import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddFacilities from "../../hospital/AddFacilities";
import { useParams } from "react-router";
import DeleteDialogBox from "../../../component/cards/DeleteDialogBox";

const OurFacilitiesAdmin = ({ facility }) => {
  const { id: OmlarId } = useParams();
  const { UrlLink } = useSelector((state) => state.user);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [currentVideoUrlVideo, setCurrentVideoUrlVideo] = useState("");

  const serialNumber = (indexNum) => {
    if (indexNum) {
      setCurrentIndex(indexNum);
    }
  };
  //------------------video play----------------------------------------------------------------

  const openModalVideo = (url) => {
    // const videoSrc = `https://api.omlarmulti.com/${url.Video}`;
    setCurrentVideoUrlVideo(url); // Set the current video URL
    setIsModalOpenVideo(true);
  };

  const closeModalVideo = () => {
    setIsModalOpenVideo(false);
    setCurrentVideoUrlVideo(""); // Clear the current video URL when closing
  };

  //----------------------------------------------------------
  const [facilitiesFromShow, srtFacilitiesFromShow] = useState(false);
  const [facilityData, srtFacilityData] = useState(false);

  const handleFaciliitesForm = (facility) => {
    srtFacilitiesFromShow(!facilitiesFromShow);
    srtFacilityData(facility);
  };

  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    // console.log("deleteActive", key);
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/admin-update-facility/${OmlarId}/${key}/`,
        headerText: `Confirm Deletion`,
        paraText: `facility`,
      });
    }
  };

  return (
    <div>
      <div className="space-y-4">
        <div className=" md:px-7 ">
          {facility &&
            facility?.map((facility, index) => (
              <div key={index} className="">
                <div className="flex gap-2 justify-end ">
                  <div
                    onClick={() => handleFaciliitesForm(facility)}
                    className=" cursor-pointer px-1 py-1 my-1 bg-gray-200 rounded-full"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => handleDeleteModel(facility?.Id)}
                    className=" cursor-pointer px-1 py-1 my-1 bg-gray-200 rounded-full"
                  >
                    Delete
                  </div>
                </div>
                <h1 className="my-2 text-xl font-semibold">
                  {facility.Facility}
                </h1>
                <div className="grid sm:grid-cols-2 md:grid-cols-4 items-center gap-1 justify-items-center cursor-pointer">
                  <img
                    onClick={() =>
                      serialNumber(UrlLink + facility.facility_image1)
                    }
                    src={UrlLink + facility.facility_image1 || banner}
                    alt="Img"
                    className="w-full h-44 object-cover border-2 border-gray-300"
                  />
                  <img
                    onClick={() =>
                      serialNumber(UrlLink + facility.facility_image2)
                    }
                    src={UrlLink + facility.facility_image2 || banner}
                    alt="Img"
                    className="w-full h-44 object-cover border-2 border-gray-300"
                  />
                  <img
                    onClick={() =>
                      serialNumber(UrlLink + facility.facility_image3)
                    }
                    src={UrlLink + facility.facility_image3 || banner}
                    alt="Img"
                    className="w-full h-44  object-cover border-2 border-gray-300 cursor-pointer"
                  />
                  <div className=" relative">
                    <video
                      onClick={() =>
                        openModalVideo(UrlLink + facility.facility_video)
                      }
                      src={UrlLink + facility.facility_video || banner}
                      className="w-full h-44  object-cover border-2 border-gray-300"
                      preload
                    ></video>
                    {/* <div
                        onClick={() =>
                          openModalVideo(UrlLink + facility.facility_video)
                        }
                        className=" absolute top-24 right-1/2"
                      >
                        <PlayCircleFilledWhiteOutlinedIcon
                          style={{ fontSize: "35px" }}
                        />
                      </div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {facilitiesFromShow && (
        <AddFacilities
          handleForm={handleFaciliitesForm}
          facilityData={facilityData}
        />
      )}

      {/* image zoom preview------------------------------------------------------------------ */}
      <div>
        {currentIndex && currentIndex !== null && (
          <div
            className={`fixed top-0 inset-0  flex items-center justify-center z-50 ${
              currentIndex !== null ? "bg-black bg-opacity-85" : "hidden"
            }`}
          >
            <div className="absolute flex gap-4 top-4 right-4 z-20">
              <div
                onClick={() => setCurrentIndex(null)}
                className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
              >
                <CloseIcon />
              </div>
            </div>
            {currentIndex ? (
              <img
                src={currentIndex}
                // src={`https://api.venturoxtech.com/${currentImage.file}`}
                alt="Imag"
                className=" h-screen shadow-lg transition-transform transform duration-300 ease-in-out scale-100  "
              />
            ) : (
              "Loading..."
            )}
            {/* <div className="absolute w-full bottom-2 flex justify-between  p-4">
                    <button
                      type="button"
                      // onClick={prevImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full rotate-180"
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                    <button
                      type="button"
                      // onClick={nextImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full "
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                  </div> */}
          </div>
        )}
      </div>

      {/* video play----------------------------------------------------------------------------- */}
      {isModalOpenVideo && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="relative bg-white p-4 rounded-lg max-w-3xl w-full">
            <button
              className="absolute top-2 right-1 z-40 text-white bg-red-500 px-3 py-1 rounded-full"
              onClick={closeModalVideo}
            >
              X
            </button>
            <video
              className="w-full "
              src={currentVideoUrlVideo} // Play the selected video in modal
              controls
              autoPlay
            />
          </div>
        </div>
      )}
      <DeleteDialogBox
          open={open}
          setOpen={setOpen}
          data={deleteModelData}
          // redirect="/tenant/person"
        />
    </div>
  );
};

export default OurFacilitiesAdmin;
