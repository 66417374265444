import React, { useState } from "react";
import blogIcon from "../../../images/blogIcon.png";
import { useSelector } from "react-redux";
import banner from "../../../images/banner.jpg";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import CloseIcon from "@mui/icons-material/Close";


const BlogSection = ({ blog }) => {
  const { DynamicColor,UrlLink } = useSelector((state) => state.user);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [currentVideoUrlVideo, setCurrentVideoUrlVideo] = useState("");

  const serialNumber = (indexNum) => {
    if (indexNum) {
      setCurrentIndex(indexNum);
    }
  };

  const openModalVideo = (url) => {
    // const videoSrc = `https://api.omlarmulti.com/${url.Video}`;
    setCurrentVideoUrlVideo(url); // Set the current video URL
    setIsModalOpenVideo(true);
  };

  const closeModalVideo = () => {
    setIsModalOpenVideo(false);
    setCurrentVideoUrlVideo(""); // Clear the current video URL when closing
  };
  console.log("blog",blog)

  //----------------------------------------Read more state -------------------------------
  const [blogExpanded, setblogExpanded] = useState(false);
  const blogReadMore = () => setblogExpanded(!blogExpanded);

  return (
    <>
      <div className="my-2 ">
        <div className="flex justify-center items-center gap-2">
          <img src={blogIcon} alt="blog" className="w-16" />
          <h1
            className="text-xl mb-2 font-extrabold border-b pb-1 "
            style={{ color: DynamicColor, borderColor: DynamicColor }}
          >
            Our Blog & <br /> Social Engagement
          </h1>
        </div>

        <div className="md:px-12 ">
          {blog &&
            blog.map((blog, index) => {
              const detailsText = blog.Our_Blog || "NA";
              const isLongText = detailsText.length > 100;

              return (
                <div key={index} className=" text-sm leading-snug text-justify">
                  <p style={{ color: DynamicColor }}>
                    {blogExpanded ? detailsText : detailsText.slice(0, 200)}
                  </p>
                  {isLongText && (
                    <span
                      onClick={blogReadMore}
                      className="flex justify-end my-3 cursor-pointer"
                    >
                      {blogExpanded ? "Show Less" : "Read More..."}
                    </span>
                  )}
                  <div key={index} className="text-xl text-center font-extrabold">
                  <div className="grid grid-cols-4 items-center gap-1 justify-items-center">
                    <img
                      onClick={() =>
                        serialNumber(UrlLink + blog.Blog_image1)
                      }
                      src={UrlLink + blog.Blog_image1 || banner}
                      alt="Img"
                      className="w-[75px]  h-[55px]  object-cover "
                    />
                    <img
                      onClick={() =>
                        serialNumber(UrlLink + blog.Blog_image2)
                      }
                      src={UrlLink + blog.Blog_image2 || banner}
                      alt="Img"
                      className=" w-[75px]  h-[55px]  object-cover "
                    />
                    <img
                      onClick={() =>
                        serialNumber(UrlLink + blog.Blog_image3)
                      }
                      src={UrlLink + blog.Blog_image3 || banner}
                      alt="Img"
                      className=" w-[75px]  h-[55px]  object-cover "
                    />
                    <div className=" relative">
                      <video
                        onClick={() =>
                          openModalVideo(UrlLink + blog.Blog_video)
                        }
                        src={UrlLink + blog.Blog_video || banner}
                        className=" w-[75px]  h-[55px] md:h-auto md:w-auto  object-cover "
                        preload
                      ></video>
                      <div
                        onClick={() =>
                          openModalVideo(UrlLink + blog.Blog_video)
                        }
                        className=" absolute top-3 right-5 sm:top-5"
                      >
                        <PlayCircleFilledWhiteOutlinedIcon
                          style={{ fontSize: "35px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                
              );
            })}
        </div>
        {/* image zoom preview------------------------------------------------------------------ */}
      <div>
        {currentIndex && currentIndex !== null && (
          <div
            className={`fixed top-0 inset-0 h-screen flex items-center justify-center z-50 ${
              currentIndex !== null ? "bg-black bg-opacity-85" : "hidden"
            }`}
          >
            <div className="absolute flex gap-4 top-4 right-4 z-20">
              <div
                onClick={() => setCurrentIndex(null)}
                className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
              >
                <CloseIcon />
              </div>
            </div>
            {currentIndex ? (
              <img
                src={currentIndex}
                // src={`https://api.venturoxtech.com/${currentImage.file}`}
                alt="Imag"
                className="border-2 shadow-lg transition-transform transform duration-300 ease-in-out scale-100  sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
              />
            ) : (
              "Loading..."
            )}
            {/* <div className="absolute w-full bottom-2 flex justify-between  p-4">
                    <button
                      type="button"
                      // onClick={prevImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full rotate-180"
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                    <button
                      type="button"
                      // onClick={nextImage}
                      className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full "
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </button>
                  </div> */}
          </div>
        )}
      </div>

      {/* video play----------------------------------------------------------------------------- */}
      {isModalOpenVideo && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="relative bg-white p-4 rounded-lg max-w-3xl w-full">
            <button
              className="absolute -top-2 -right-1 text-white bg-red-500 px-3 py-1 rounded-full"
              onClick={closeModalVideo}
            >
              X
            </button>
            <video
              className="w-full h-64 sm:h-96"
              src={currentVideoUrlVideo} // Play the selected video in modal
              controls
              autoPlay
            />
          </div>
        </div>
      )}
        
      </div>
    </>
  );
};

export default BlogSection;
