import React, { useEffect, useRef, useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logoutUser } from "../../store/feature/userSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import { AddButton } from "../../component/props/Buttons";

const initialValues = {
  Name: "",
  Image: "",
};

const CategoryDetail = () => {
  const CreateSchema = Yup.object({
    Name: Yup.string().required("Name is required."),

    Image: Yup.string().required("Address is required."),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [loading, setLoading] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      if(values.Name !== allDataRaw.Name)
      data.append("Name", values.Name);
      if (docs) {
        data.append("Image", docs);
      }
     

      try {
        const response = await getAxiosWithOutToken({
          method: "POST",
          url: `Omlar_login_register/categories/edit/${OmlarId}/`,
          data: data,
          reqType: "formData",
        });
        if (response.data) {
          alertResponseMsgSuccess("Successful...");
          // handleForm(); // Close form on successful submission
          action.resetForm();
          navigate(-1);
          
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.msg) {
          alertResponseMsgError(error.response.data.msg);
        } else {
          alertResponseMsgError("An error occurred");
        }
      }
    },
  });

  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `Omlar_login_register/categories/${OmlarId}/`,
      });
      if (response && response.data) {
        setAllDataRaw(response.data);
        values.Name = response.data.Name;
        values.Image = response.data.Image;
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        await dispatch(logoutUser());
      } else {
        navigate("/404");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    if (isLogged) {
      getDetail();
    }
  }, [isLogged]);

  // console.log("values.Image", `https://api.omlarmulti.com/${values.Image}`);
  //----------------------Doucments ---------------------------------------
const fileInputRef = useRef(null);
const [docs, setDocs] = useState(null);
console.log("docs",docs)

const handleChangeFile = (e) => {
  const { files } = e.target;
  const file = files[0];

  if (file) {
    // Validate file size (limit to 2 MB)
    if (file.size > 2000000) {
      alertResponseMsgError("File size should be less than 2 MB");
      return false;
    }

    // Validate file type (allow only PDF, DOCX, JPG, JPEG, PNG)
    const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/jpg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      alertResponseMsgError("File format should be PDF, DOCX, JPG, JPEG, or PNG");
      return false;
    }

    // If the file is valid, update the state
    setDocs(file);
    // setFieldValue("upload_document", file); // Update Formik state

    // If you want to preview image files (optional)
    // if (file.type.startsWith("image/")) {
    //   const imageUrl = URL.createObjectURL(file);
    //   // Uncomment the line below to use for image preview
    //   // setProfilePicPerview(imageUrl);
    // }
  }
};

  return (
    <>
      <div className="bg-white ">
        {loading && <div className=""></div>}

        <form onSubmit={handleSubmit} className=" md:p-12 p-4 ">
          <div className="mb-6 ">
            {/* Label for Name */}
            <label htmlFor="Name" className="form-label">
              Name
            </label>
            <input
              type="text"
              id="Name"
              name="Name"
              placeholder="Enter name"
              onChange={handleChange}
              className="form-input"
              value={values.Name || ""}
            />
            <InputError
              error={errors.Name}
              touched={touched.Name}
              warning={false}
            />
          </div>

          <div className="mb-6">
            {/* Label for Date of Birth */}
            <label htmlFor="dob" className="form-label">
              Image
            </label>
            <input
              type="file"
              id="Image"
              name="Image"
              className="form-input"
              ref={fileInputRef}
              onChange={handleChangeFile}
              // value={values.Image || ""}
            />
          </div>
          <div className=" flex justify-center bg-gray-100 p-2">
            <img
              src={`https://api.omlarmulti.com/media/categories_image/${values.Image}`}
              alt="banner"
              className="h-96 ring-white bg-white"
            />
          </div>
          <div className="flex justify-end mt-6">
            <AddButton type="submit" name="Submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default CategoryDetail;
