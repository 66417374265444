import React from "react";
import emergency from "../../images/emergency.png";
import ambulance from "../../images/ambulance.png";
import doctor from "../../images/doctor.png";
import truama from "../../images/truama.png";
import phone from "../../images/phone.png";
import alltime from "../../images/alltime.png";
import blood from "../../images/blood.png";

const EmergencyDesk = ({ allDataRaw }) => {
  return (
    <>
      <div className="my-4">
        <div className="flex items-center justify-center gap-2">
          <img src={emergency} alt="emergency" className="w-16 animate-pulse" />
          <h1 className="text-xl mb-2 font-extrabold text-red-600 p-2">
            Emergency Desk
          </h1>
        </div>
        <div className="grid grid-cols-3 items-end">
          <div className="font-bold text-center my-3">
            <div className="flex justify-center my-1">
              <img src={alltime} alt="emergency" className="h-16" />
            </div>
            <h2 className="text-[9px]">24x7 Emergency Service</h2>
            <p className="text-[14px] text-blue-800">
              Call us:{" "} 
              <a
                href={`tel:${allDataRaw?.Emergency_No || ""}`}
                className="text-blue-800"
              >
                {allDataRaw?.Emergency_No || "Na"}
              </a>
            </p>
          </div>
          <div className="font-bold text-center my-3">
            <div className="flex justify-center my-1">
              <img src={ambulance} alt="emergency" className="h-14" />
            </div>
            <h2 className="text-[10px]">Ambulance Service</h2>
            <p className="text-[13px] text-blue-800">
            Call us:{" "} 
              <a
                href={`tel:${allDataRaw?.Ambulance_Contact || ""}`}
                className="text-blue-800"
              >
                {allDataRaw?.Ambulance_Contact || "Na"}
              </a>
            </p>
          </div>
          <div className="font-bold text-center my-3">
            <div className="flex justify-center my-1">
              <img src={truama} alt="emergency" className="h-16" />
            </div>
            <h2 className="text-[9px]">Trauma & Accident Care</h2>
            <p className="text-[13px] text-blue-800">
            Call us:{" "} 
              <a
                href={`tel:${allDataRaw?.Trauma_Accidental_Care || ""}`}
                className="text-blue-800"
              >
                {allDataRaw?.Trauma_Accidental_Care || "Ambulance Contact"}
              </a>
            </p>
          </div>
          <div className="font-bold text-center my-3">
            <div className="flex justify-center my-1">
              <img src={doctor} alt="emergency" className="h-16" />
            </div>
            <h2 className="text-[9px]">Emergency Care Doctor</h2>
            <p className="text-[13px] text-blue-800">
            Call us:{" "} 
              <a
                href={`tel:${allDataRaw?.Emergency_Care_Doctor || ""}`}
                className="text-blue-800"
              >
                {allDataRaw?.Emergency_Care_Doctor || "NA"}
              </a>
            </p>
          </div>
          <div className="font-bold text-center my-3">
            <div className="flex justify-center my-1">
              <img src={blood} alt="emergency" className="h-16" />
            </div>
            <h2 className="text-[9px]">Blood Bank Service</h2>
            <p className="text-[13px] text-blue-800">
            Call us:{" "} 
              <a
                href={`tel:${allDataRaw?.Blood_Bank_No || ""}`}
                className="text-blue-800"
              >
                {allDataRaw?.Blood_Bank_No || "NA"}
              </a>
            </p>
          </div>
          <div className="font-bold text-center my-3">
            <div className="flex justify-center my-1">
              <img src={phone} alt="emergency" className="h-16" />
            </div>
            <h2 className="text-[9px]">
              Telemedicine for Emergency Consultations
            </h2>
            <p className="text-[13px] text-blue-800">
            Call us:{" "} 
              <a
                href={`tel:${
                  allDataRaw?.Telemedicine_for_Emergency_Consultations || ""
                }`}
                className="text-blue-800"
              >
                {allDataRaw?.Telemedicine_for_Emergency_Consultations || "NA"}
              </a>
            </p>
          </div>
        </div>

        {/* <div className="md:px-12 pl-5">
          <div className="">
            <h1 className="text-lg mb-2 font-extrabold">
              &#9679; We are available 24x7
            </h1>
          </div>

          <div className=" ">
            <h1 className="text-lg  font-extrabold py-2">
              &#9679; Hospital Ambulance Contact No.
            </h1>
            <p className="text-lg  font-extrabold text-blue-600 text-center">
              {allDataRaw?.Ambulance_Contact || "Ambulance Contact"}
            </p>
          </div>
          <div className=" ">
            <h1 className="text-lg  font-extrabold  p-2">
              &#9679; Trauma & Accident Care
            </h1>
            <p className="text-lg  font-extrabold text-blue-600 text-center">
              {allDataRaw?.Trauma_Accidental_Care || "Trauma Accidental Care"}
            </p>
          </div>
          <div className=" ">
            <h1 className="text-lg  font-extrabold  p-2">
              &#9679; Emergency Care Dr. Contact No.
            </h1>
            <p className="text-lg  font-extrabold text-blue-600 text-center">
              {allDataRaw?.Emergency_No || "Emergency No."}
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default EmergencyDesk;
