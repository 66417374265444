import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithOutToken, } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/props/InputError";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

const initialValues = {
  name: "",
  phone: "",
  Test_type: "",
  address: "",
  homecare_services_type: "",
  upload_document: "",
};

const TestForm = ({ handleForm, name = "DIAGNOSTIC/LAB TESTS" }) => {
  const { DynamicColor } = useSelector((state) => state.user);

  const CreateSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be numeric")
      .required("Phone number is required."),
    address: Yup.string().required("Address is required."),
    Test_type: Yup.string().required("Test type is required."),
    // homecare_services_type: Yup.string().required("Service type is required."),
    upload_document: Yup.mixed().required("Document is required."),
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CreateSchema,
      onSubmit: async (values, action) => {
        let data = new FormData();
        data.append("name", values.name);
        data.append("phone", values.phone);
        data.append("address", values.address);
        data.append("Test_type", values.Test_type);
        data.append("homecare_services_type", name);
        if (docs) {
          data.append("upload_document", docs);
        }

        try {
          const response = await getAxiosWithOutToken({
            method: "POST",
            url: "crud/homecare-services/",
            data: data,
            reqType: "formData",
          });
          if (response.data) {
            alertResponseMsgSuccess("Successful...");
            handleForm(); // Close form on successful submission
            action.resetForm();
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            alertResponseMsgError(error.response.data.msg);
            
          } else {
            alertResponseMsgError("Something wents wrong");
          }
        }
      },
    });

  //----------------------Doucments ---------------------------------------
  const fileInputRef = useRef(null);
  const [docs, setDocs] = useState(null);
  // console.log("docs",docs)

  const handleChangeFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      // Validate file size (limit to 2 MB)
      if (file.size > 2000000) {
        alertResponseMsgError("File size should be less than 2 MB");
        return false;
      }

      // Validate file type (allow only PDF, DOCX, JPG, JPEG, PNG)
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      if (!allowedTypes.includes(file.type)) {
        alertResponseMsgError(
          "File format should be PDF, DOCX, JPG, JPEG, or PNG"
        );
        return false;
      }

      // If the file is valid, update the state
      setDocs(file);
      setFieldValue("upload_document", file); // Update Formik state

      // If you want to preview image files (optional)
      // if (file.type.startsWith("image/")) {
      //   const imageUrl = URL.createObjectURL(file);
      //   // Uncomment the line below to use for image preview
      //   // setProfilePicPerview(imageUrl);
      // }
    }
  };
  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
          <div className="bg-white pb-3 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-xl  font-bold mb-6 text-center" style={{color:DynamicColor}}>
              {name}
            </h2>
            <div className="border-4 bg-white m-4 " style={{borderColor:DynamicColor}}>
              <form onSubmit={handleSubmit}>
                <div className="p-4">
                  {/* Name */}
                  <div className="mb-4">
                    <label htmlFor="name" className="form-label">
                      Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      className="popform-input "
                      value={"" || values.name}
                      onChange={handleChange}
                    />
                    <InputError
                      error={errors.name}
                      touched={touched.name}
                      warning={false}
                    />
                  </div>

                  {/* Mobile No. */}
                  <div className="mb-4">
                    <label htmlFor="phone" className="form-label">
                      Mobile No.*
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Enter your mobile number"
                      className="popform-input "
                      value={"" || values.phone}
                      onChange={handleChange}
                    />
                    <InputError
                      error={errors.phone}
                      touched={touched.phone}
                      warning={false}
                    />
                  </div>
                  {/* Address */}
                  <div className="mb-4">
                    <label htmlFor="address" className="form-label">
                      Address *
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Enter your address"
                      className="popform-input "
                      value={"" || values.address}
                      onChange={handleChange}
                    />
                    <InputError
                      error={errors.address}
                      touched={touched.address}
                      warning={false}
                    />
                  </div>
                  {/* Test Type */}
                  <div className="mb-4">
                    <label htmlFor="Test_type" className="form-label">
                      Test Type *
                    </label>
                    <input
                      type="text"
                      id="Test_type"
                      name="Test_type"
                      placeholder="Blood Test / Urine Test etc."
                      className="popform-input "
                      value={"" || values.Test_type}
                      onChange={handleChange}
                    />
                    <InputError
                      error={errors.Test_type}
                      touched={touched.Test_type}
                      warning={false}
                    />
                  </div>
                  {/*  upload_document */}
                  <div className="mb-4">
                    <label htmlFor="upload_document" className="form-label">
                      Upload Prescription (pdf, docx) *
                    </label>
                    <input
                      type="file"
                      id="upload_document"
                      name="upload_document"
                      accept=".pdf,.docx"
                      className="popform-input"
                      // value={"" || values.upload_document}
                      ref={fileInputRef}
                      onChange={handleChangeFile}
                    />
                    <InputError
                      error={errors.upload_document}
                      touched={touched.upload_document}
                      warning={false}
                    />
                  </div>

                  {/* Inquiry Contact */}
                  {/* <div>
                    <h1 className="text-xl font-bold">
                      Contact Us: 8707858958
                    </h1>
                  </div> */}
                </div>

                {/* Submit Button */}
                <div className="flex justify-center mt-6">
                  <AddButton type="submit" name="Submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestForm;
