import React, { useEffect, useState } from "react";
import DashChart from "../../component/cards/DashChart";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Chart } from "react-google-charts";
import BarsDataset from "../../component/charts/BarCharts";
import OnSeriesItemClick from "../../component/charts/PieChart";
import PieChartWithCustomizedLabel from "../../component/charts/ExpenseChart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
import { PathRoute } from "../../router/PathRoute";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  //--------------------------get details------------------------------------------------
  const getAllData = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "Omlar_login_register/dashboard/count/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await dispatch(logoutUser());
        navigate("/");
      } else {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      getAllData();
    }
  }, [isLogged]);

  const data = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2020", 1000, 400, 600],
    ["2021", 1170, 460, 710],
    ["2022", 660, 1120, -460],
    ["2023", 1030, 540, 490],
  ];

  const options = {
    title: "Company Performance",
    hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
    vAxis: { minValue: 0 },
    chartArea: { width: "70%", height: "70%" },
    colors: ["#1b9e77", "#d95f02", "#7570b3"],
    legend: { position: "top" },
    animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
    },
  };

  return (
    <div>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
        <Link to={PathRoute.Users}>
          <DashChart
            title="Total User"
            icon={<VisibilityOutlinedIcon />}
            total={allDataRaw?.omlar_user_count}
            // rate="0.43%"
            levelUp
          />
        </Link>
        <Link to={PathRoute.Business}>
          <DashChart
            title="Total Business"
            icon={<VisibilityOutlinedIcon />}
            total={allDataRaw?.asit_business_count}
            levelUp
          />
        </Link>

        <DashChart
          title="Total Booking"
          icon={<VisibilityOutlinedIcon />}
          total={allDataRaw?.asit_omlar_booking_summary_count}
          levelUp
        />
        <DashChart
          title="Total Share"
          icon={<VisibilityOutlinedIcon />}
          total={allDataRaw?.asit_omlar_share_count}
          levelUp
        />
      </div>
      <div className="grid grid-cols-6  gap-4 my-4 ">
        <div className="p-4 w-full md:col-span-4 col-span-6 shadow-md bg-white rounded-borderRedius">
          <BarsDataset />
        </div>
        <div className="p-4 flex items-center justify-center md:col-span-2 col-span-6 shadow-md bg-white rounded-borderRedius">
          <PieChartWithCustomizedLabel />
        </div>
        <div className="flex items-center p-4 w-full md:col-span-2 col-span-6 shadow-md bg-white rounded-borderRedius">
          <OnSeriesItemClick />
        </div>
        <div className="p-4 w-full md:col-span-4 col-span-6 shadow-md bg-white rounded-borderRedius">
          <Chart
            chartType="AreaChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
