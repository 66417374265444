import React, { useEffect, useState } from "react";
import Dynamictable from "../../component/table/Dynamictable";
// import SearchBar from "../../component/props/SearchBar";
import PageName from "../../component/props/PageName";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
// import { PathRoute } from "../../router/PathRoute";

const ShareHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const getAllShare = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "Other_crud/share/",
      });

      if (response && response.data.results) {
        setAllDataRaw(response.data.results);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await dispatch(logoutUser());
        navigate("/");
      } else {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      getAllShare();
    }
  }, [isLogged]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.Business_Id,
          item2: option.User_Id || "NA  ",

          // uniqueKey: option.Business_Id,
          // link: PathRoute.CategoryDetailLink
        };
      });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);
  const tableHeadings = ["S.No", "Business ID","user ID"];
  return (
    <div className="md:p-4">
      <PageName name="Share History"  />
      {/* <SearchBar /> */}
      <Dynamictable tableData={tableData} tableHead={tableHeadings} />
    </div>
  );
};

export default ShareHistory;
