import React, { useEffect, useState } from "react";
import Dynamictable from "../../component/table/Dynamictable";
// import SearchBar from "../../component/props/SearchBar";
import PageName from "../../component/props/PageName";
import { PathRoute } from "../../router/PathRoute";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser } from "../../store/feature/userSlice";
// import AddBackgroundImage from "./backgroundImage/AddBackgroundImage";
// import { TableButton } from "../../component/props/Buttons";
// import AddAds from "./ads/AddAds";
// import Wishes from "./wishes/Wishes";

const Bussiness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("allDataRaw",allDataRaw);
  const getBusinessDetail = async () => {
    try {
      setLoading(true); // Start loading
      const response = await getAxiosWithToken({
        method: "get",
        url: "Omlar_login_register/businesses_detail_delete/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data.results);
        console.log("response.data",response.data)
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        await dispatch(logoutUser());
      } else {
        navigate("/404");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isLogged) {
      getBusinessDetail();
    }
  }, [isLogged]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.Business_Name || "NA",
          item2: option.Contact || "NA",
          item3: option.Email || "NA",
          // item4: option.Owner || "NA",
          event: "event",
          // whatsappToken: "token",
          uniqueKey: option.Business_Id,
          link: PathRoute.BusinessDetailLink,
        };
      });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);
  const tableHeadings = [
    "S.N.",
    "Business Name",
    "Contact",
    "Email",
    // "Owner",
    "Event",
  ];

  // const [addImage, setAddImage] = useState(false);
  // const [addAds, setAddAds] = useState(false);
  // const [wishespop, setwishespop] = useState(false);

  // const [showdropDown, setshowdropDown] = useState(false);

  // const dropDown = () => {
  //   setshowdropDown(!showdropDown);
  // };

  // const handleForm = () => {
  //   setAddImage(!addImage);
  // };
  // const handleWishespop = () => {
  //   setwishespop(!wishespop);
  // };
  // const handleAdsForm = () => {
  //   setAddAds(!addAds);
  // };

  // console.log("params",params)
  return (
    <div className="md:p-4">
      {/* href={PathRoute.AddBusiness} */}
      {/* <div className="flex flex-wrap justify-between">
        <PageName name="Business" />
        <div className="flex items-center gap-2">
        <div onClick={handleForm}>
          <TableButton name="Add Backgound Image" />
        </div>
        <div onClick={handleAdsForm}>
          <TableButton name="Add Ads" />
        </div>
        <div onClick={handleAdsForm}>
          <TableButton name="Wishes" />
        </div>
        </div>
       
      </div> */}
      <div className="flex flex-wrap justify-between">
        <PageName name="Business" />
        {/* <div className="relative inline-block text-left">
          <button onClick={dropDown} className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2  focus:ring-gray-500">
            Actions
          </button>
          {showdropDown &&  <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white border border-gray-300 rounded-md shadow-lg">
            <div onClick={dropDown} className="py-1">
              <button
                onClick={handleForm}
                className="dropdownOption"
              >
                Add Background Image
              </button>
              <button
                onClick={handleAdsForm}
                className="dropdownOption"
              >
                Add Ads
              </button>
              <button
                onClick={handleWishespop}
                className="dropdownOption"
              >
                Wishes
              </button>
            </div>
          </div>}
         
        </div> */}
      </div>

      {/* {addImage && <AddBackgroundImage handleForm={handleForm} />}
      {addAds && <AddAds handleForm={handleAdsForm} />}
      {wishespop && <Wishes handleForm={handleWishespop} />} */}

      {/* <SearchBar /> */}

      <Dynamictable
        tableData={tableData}
        tableHead={tableHeadings}
        loading={loading}
      />
    </div>
  );
};

export default Bussiness;
